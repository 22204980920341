import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 600.000000 480.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,480.000000) scale(0.100000,-0.100000)">


<path d="M2165 4713 c-69 -15 -119 -53 -143 -106 -9 -22 -12 -358 -12 -1525 0
-1439 1 -1499 19 -1532 10 -19 35 -48 55 -65 l38 -30 747 0 747 0 41 27 c68
45 76 71 81 250 l4 158 -56 0 -56 0 0 -65 0 -65 -758 0 -758 0 0 1298 1 1297
755 0 755 0 3 -522 2 -523 55 0 55 0 0 618 c0 393 -4 630 -10 654 -13 45 -51
93 -94 116 -28 16 -91 17 -741 19 -390 0 -719 -1 -730 -4z m945 -178 l0 -25
-240 0 -240 0 0 25 0 25 240 0 240 0 0 -25z"/>
<path d="M3072 3783 l3 -48 48 -3 47 -3 0 50 0 51 -51 0 -51 0 4 -47z"/>
<path d="M2307 3633 c-4 -3 -7 -26 -7 -50 l0 -43 50 0 50 0 0 50 0 50 -43 0
c-24 0 -47 -3 -50 -7z"/>
<path d="M3130 3168 c-1 -2 -3 -58 -6 -126 l-4 -123 127 3 128 3 0 120 0 120
-122 3 c-68 1 -123 1 -123 0z"/>
<path d="M3990 3067 l0 -102 -118 -118 -117 -117 -98 0 -97 0 0 -98 0 -97
-118 -118 -117 -117 -103 0 -102 0 0 -125 0 -125 125 0 125 0 0 98 0 98 117
117 117 117 103 0 103 0 0 103 0 104 118 116 117 117 98 0 97 0 0 125 0 125
-125 0 -125 0 0 -103z"/>
<path d="M2698 3028 c-2 -46 -2 -95 0 -110 l3 -28 105 0 104 0 0 110 0 110
-104 0 -105 0 -3 -82z"/>
<path d="M2430 2655 l0 -45 45 0 45 0 0 45 0 45 -45 0 -45 0 0 -45z"/>
<path d="M980 1281 c-144 -33 -236 -155 -228 -301 6 -113 65 -198 171 -245 52
-24 69 -27 131 -23 83 6 134 29 188 85 55 57 78 115 78 199 0 123 -66 222
-178 270 -43 18 -118 25 -162 15z m144 -157 c96 -66 85 -214 -22 -265 -109
-53 -233 36 -219 157 12 114 145 173 241 108z"/>
<path d="M2690 1281 c-245 -48 -321 -359 -124 -510 58 -44 106 -60 179 -60
147 -1 269 111 282 260 l6 59 -157 0 -156 0 0 -65 0 -65 73 0 72 -1 -27 -24
c-92 -81 -248 -7 -248 119 0 92 63 156 153 156 46 0 107 -24 107 -42 0 -17 25
-7 71 28 26 20 45 42 43 48 -11 25 -97 77 -151 91 -61 16 -69 16 -123 6z"/>
<path d="M3735 1274 c-88 -49 -120 -143 -76 -224 23 -45 66 -73 149 -101 69
-22 102 -43 102 -64 0 -24 -37 -45 -79 -45 -34 0 -49 6 -73 30 -22 23 -36 29
-51 24 -34 -11 -87 -48 -87 -61 0 -22 57 -78 105 -102 90 -45 236 -16 290 57
14 20 20 43 20 88 0 99 -40 138 -207 198 -54 20 -72 51 -42 73 31 22 74 16
102 -16 l27 -28 50 30 c27 17 51 31 53 32 10 7 -27 55 -64 84 -40 30 -53 35
-117 38 -54 3 -79 -1 -102 -13z"/>
<path d="M1380 1000 l0 -280 70 0 69 0 3 153 3 152 64 -108 c43 -72 69 -107
80 -105 8 2 42 49 76 105 33 56 63 103 68 103 4 0 7 -67 7 -150 l0 -150 65 0
65 0 0 280 0 280 -68 0 -68 0 -69 -120 c-39 -66 -74 -118 -80 -117 -5 2 -14
12 -20 23 -5 11 -37 63 -70 117 l-59 97 -68 0 -68 0 0 -280z"/>
<path d="M2060 1000 l0 -280 175 0 175 0 0 60 0 59 -107 3 -108 3 -3 48 -3 47
100 0 101 0 0 65 0 65 -101 0 -100 0 3 41 3 41 105 2 105 1 3 63 3 62 -176 0
-175 0 0 -280z"/>
<path d="M3243 1218 c-12 -35 -31 -88 -43 -118 -24 -64 -60 -165 -97 -270 -14
-41 -30 -83 -35 -92 -8 -16 -2 -18 64 -18 l73 0 18 50 18 50 98 0 99 0 18 -50
18 -50 68 0 c37 0 68 3 68 8 0 4 -44 129 -98 277 l-98 270 -75 3 -75 3 -21
-63z m124 -207 c12 -33 19 -62 16 -65 -10 -10 -93 -7 -93 4 0 15 44 120 51
120 3 0 15 -27 26 -59z"/>
<path d="M4090 1088 c0 -213 7 -252 51 -303 95 -108 282 -99 364 18 l30 42 3
218 3 217 -65 0 -66 0 0 -187 c0 -131 -4 -196 -13 -213 -22 -44 -113 -53 -151
-14 -14 13 -16 47 -16 215 l0 199 -70 0 -70 0 0 -192z"/>
<path d="M4626 1032 c1 -136 2 -262 3 -279 l1 -33 145 0 c160 0 192 8 236 56
54 59 63 157 19 216 -21 28 -21 30 -4 73 28 73 -2 149 -77 194 -30 19 -50 21
-179 21 l-145 0 1 -248z m279 78 l0 -35 -72 -3 -73 -3 0 39 c0 21 2 41 4 43 2
2 35 2 73 -1 l68 -5 0 -35z m13 -172 c7 -7 12 -27 12 -45 0 -42 -30 -56 -110
-51 l-55 3 -3 53 -3 52 73 0 c44 0 79 -5 86 -12z"/>
<path d="M3815 601 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z"/>
<path d="M4448 525 c2 -57 7 -85 15 -85 9 0 11 25 9 85 -2 57 -7 85 -15 85 -9
0 -11 -25 -9 -85z"/>
<path d="M4775 525 c0 -61 3 -85 13 -85 9 0 12 24 12 85 0 61 -3 85 -12 85
-10 0 -13 -24 -13 -85z"/>
<path d="M2889 596 c-6 -11 -5 -36 2 -36 5 0 9 5 9 10 0 6 11 10 25 10 24 0
25 -3 25 -58 0 -32 -5 -63 -12 -70 -9 -9 -4 -12 22 -11 19 0 29 3 23 6 -9 3
-13 27 -13 69 0 61 1 64 24 64 13 0 27 -6 30 -12 3 -7 5 -4 3 7 -2 18 -10 20
-70 21 -37 1 -67 1 -68 0z"/>
<path d="M3419 593 c-8 -65 14 -153 37 -153 12 0 11 5 -2 25 -12 18 -15 40
-12 80 4 41 1 55 -9 55 -7 0 -13 -3 -14 -7z"/>
<path d="M3528 537 c3 -50 1 -67 -13 -80 -9 -9 -12 -17 -6 -17 5 0 17 10 25
22 20 29 22 138 3 138 -10 0 -12 -16 -9 -63z"/>
<path d="M4315 594 c-6 -2 -9 -32 -7 -70 3 -48 0 -69 -10 -75 -9 -6 -6 -9 12
-9 25 0 25 1 23 76 -1 80 -2 83 -18 78z"/>
<path d="M4380 592 c0 -5 7 -13 16 -16 10 -4 14 -1 12 6 -5 15 -28 23 -28 10z"/>
<path d="M3069 547 c-17 -9 -25 -22 -27 -45 -4 -40 12 -62 45 -62 76 0 87 74
17 113 -5 3 -21 0 -35 -6z m57 -31 c9 -38 -4 -66 -31 -66 -24 0 -35 17 -35 54
0 29 8 36 36 36 17 0 25 -7 30 -24z"/>
<path d="M3188 464 c3 -60 7 -84 15 -81 7 2 11 15 9 30 -3 23 0 26 35 29 35 3
39 6 47 40 13 51 -3 68 -63 68 l-46 0 3 -86z m90 53 c7 -37 -7 -67 -33 -67
-27 0 -39 25 -29 65 5 21 11 26 33 23 16 -2 27 -10 29 -21z"/>
<path d="M3598 465 c2 -47 8 -85 13 -85 10 0 13 37 9 113 -2 46 -1 47 28 47
24 0 31 -5 36 -26 9 -33 -1 -62 -22 -67 -16 -4 -16 -5 0 -6 38 -3 60 48 38 90
-8 15 -21 19 -58 19 l-47 0 3 -85z"/>
<path d="M3810 495 c0 -37 4 -55 13 -55 8 0 11 16 9 55 -5 71 -22 71 -22 0z"/>
<path d="M3885 495 c0 -37 4 -55 13 -55 8 0 12 18 12 55 0 37 -4 55 -12 55 -9
0 -13 -18 -13 -55z"/>
<path d="M3937 543 c18 -3 22 -12 25 -50 2 -26 8 -48 13 -50 7 -2 9 16 5 49
-5 51 -6 53 -35 55 -19 1 -22 -1 -8 -4z"/>
<path d="M4095 540 c-8 -13 5 -13 25 0 13 8 13 10 -2 10 -9 0 -20 -4 -23 -10z"/>
<path d="M4158 525 c3 -20 0 -25 -16 -25 -36 0 -52 -11 -52 -36 0 -18 5 -24
23 -23 12 0 16 3 10 6 -7 2 -13 13 -13 24 0 14 7 19 25 19 20 0 25 -5 25 -25
0 -14 6 -25 13 -25 16 0 10 85 -8 100 -9 8 -11 4 -7 -15z"/>
<path d="M4520 540 c0 -5 7 -7 15 -4 8 4 15 8 15 10 0 2 -7 4 -15 4 -8 0 -15
-4 -15 -10z"/>
<path d="M4578 495 c3 -34 8 -55 16 -55 8 0 10 16 6 52 -7 71 -26 74 -22 3z"/>
<path d="M4661 541 c-19 -12 -7 -39 23 -51 31 -12 42 -30 25 -42 -12 -8 -11
-9 1 -5 8 2 16 13 18 26 3 17 -4 24 -28 33 -24 8 -30 15 -27 29 3 11 4 19 4
19 -1 0 -8 -4 -16 -9z"/>
<path d="M4700 546 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M4839 541 c7 -5 16 -29 18 -55 3 -25 10 -46 15 -46 6 0 7 7 4 16 -3
9 -6 33 -6 55 0 34 -3 39 -22 39 -17 0 -19 -3 -9 -9z"/>
<path d="M4365 520 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M3330 506 c0 -10 28 -13 41 -5 10 6 7 9 -13 9 -16 0 -28 -2 -28 -4z"/>
<path d="M4520 485 c-17 -21 -5 -45 22 -44 17 1 18 2 6 6 -25 6 -22 32 5 43
22 9 22 9 1 9 -12 1 -27 -6 -34 -14z"/>
<path d="M4650 449 c0 -5 9 -9 20 -9 11 0 20 2 20 4 0 2 -9 6 -20 9 -11 3 -20
1 -20 -4z"/>
</g>
</svg>

		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
